<template>
  <location-autocomplete
    ref="autocomplete"
    v-model:search="search"
    v-model:suggestions="suggestions"
    :disabled="disabled"
    :errors="errors"
    :hide-suggestions="hideSuggestions"
    :inverse="inverse"
    :label="$t('search.filter.label.origin')"
    :message="inputHint"
    :placeholder="$t('search.filter.value.selectOrigin')"
    :suggestion-component="OriginSuggestion"
    :suggestion-empty-helper="$t('search.filter.origin.emptyResultsHelper')"
    :suggestion-empty-title="$t('search.filter.origin.emptyResults')"
    :suggestion-helper="$t('search.filter.origin.helper')"
    @clear="onClear"
    @focus="onFocus"
    @debounced-input="onDebouncedInput"
    @keydown.enter="$emit('keydown.enter')"
    @suggestion-picked="onSuggestionPicked" />
</template>

<script lang="ts" setup>
import type {
  SearchOrigin as GQLSearchOrigin,
  SearchOriginResultType,
} from '~/domains/graphql';
import { useSearchAirportOriginQuery } from '~/domains/graphql';
import type { SearchOrigin } from '~/domains/search';
import type { AiSuggestion } from '~/domains/ux/molecules/AiInput/interfaces';

import LocationAutocomplete from './LocationAutocomplete.vue';
import OriginSuggestion from './OriginSuggestion.vue';

type Props = {
  disabled?: boolean;
  errors?: string[];
  hideSuggestions?: boolean;
  inputHint?: string;
  inverse?: boolean;
  modelValue: SearchOrigin;
};
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  errors: undefined,
  hideSuggestions: false,
  inputHint: '',
  inverse: false,
});

type Emits = {
  (event: 'update:modelValue', value: SearchOrigin): void;
  (event: 'origins-fetched', value: GQLSearchOrigin[]): void;
  (event: 'keydown.enter'): void;
};
const emits = defineEmits<Emits>();

const autocomplete = ref<typeof LocationAutocomplete>();

const search = computed({
  get() {
    return props.modelValue;
  },
  set(value: SearchOrigin) {
    emits('update:modelValue', value);
  },
});

const { onResult, refetch, result, start, stop } = useSearchAirportOriginQuery(
  {
    search: search.value.text as string,
  },
  {
    fetchPolicy: 'no-cache',
  },
);

const onOriginsFetched = (origins: GQLSearchOrigin[] = []) => {
  emits('origins-fetched', origins);
};

onResult(() => {
  onOriginsFetched(
    result.value?.searchAirportOrigin?.origins as GQLSearchOrigin[],
  );
});

const onDebouncedInput = () => {
  if (search.value.text) {
    refetch({
      search: search.value.text,
    });
  } else {
    result.value = undefined;
    onOriginsFetched();
  }
};

const suggestions = computed<AiSuggestion<GQLSearchOrigin>[] | null>(() => {
  if (!result.value?.searchAirportOrigin) {
    return null;
  }

  return (result.value?.searchAirportOrigin?.origins ?? []).map<
    AiSuggestion<GQLSearchOrigin>
  >(item => {
    return {
      key: item.id,
      props: item,
      value: item.name,
    };
  });
});

const onClear = () => {
  search.value = { text: '' };
  result.value = undefined;
  start();
};

const onFocus = () => {
  refetch({
    search: search.value.text,
  });
};

const onSuggestionPicked = (suggestion: AiSuggestion) => {
  stop();

  const selectedOrigin = suggestion.props as GQLSearchOrigin;

  search.value = {
    airportCode: selectedOrigin.code as string,
    text: suggestion.value as string,
    regionId: selectedOrigin.id as string,
    type: selectedOrigin.type as SearchOriginResultType,
  };

  result.value = undefined;
};

const input = computed(() => autocomplete.value?.input);

defineExpose({ input });
</script>
