<template>
  <location-suggestion
    :icon="icon"
    :subtitle="labels.subtitle"
    :title="labels.title"
    @click="$emit('click', props)" />
</template>

<script lang="ts" setup>
import { SearchOriginResultType } from '~/domains/graphql';
import { IconName } from '~/domains/ux/atoms/AiIcon/types';

export type Props = {
  country: string;
  fullName: string;
  id: string;
  name: string;
  type: SearchOriginResultType;
};

type Emits = {
  (event: 'click', value: Props): void;
};
defineEmits<Emits>();

const props = defineProps<Props>();

const { t } = useI18n();

const icon = computed<IconName>(() => {
  if (props.type === SearchOriginResultType.Airport) {
    return 'airport';
  }

  return 'pin-outline';
});

const labels = computed(() => {
  if (props.type === SearchOriginResultType.Airport) {
    return {
      title: props.name,
      subtitle: t('search.filter.origin.airport', {
        country: props.country,
      }),
    };
  }

  return {
    title: props.name,
    subtitle: 'country',
  };
});
</script>
