import { useExpediaFeatureAsyncData } from '~/domains/graphql';

type UseFlightHotelAvailability = () => {
  isFlightHotelAvailable: boolean;
};

export const useFlightHotelAvailability: UseFlightHotelAvailability = () => {
  const { locale } = useI18n();
  const localization = useLocalizationHeader();
  const { data } = useExpediaFeatureAsyncData();

  const { countries, languages } = data.value?.globalConfiguration?.expedia ?? {
    countries: [],
    languages: [],
  };

  const normalize = (value: string) => value.split(/\W/)[0].toLowerCase();

  const normalizedLanguages = languages.map(normalize);
  const normalizedLocale = normalize(locale.value ?? '');
  const normalizedCountries = countries.map(normalize);
  const normalizedLocalization = normalize(localization.value ?? '');

  const isFlightHotelAvailable =
    normalizedLanguages.includes(normalizedLocale) &&
    normalizedCountries.includes(normalizedLocalization);

  return {
    isFlightHotelAvailable,
  };
};
