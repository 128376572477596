<template>
  <button class="LocationSuggestion" @click="$emit('click', props)">
    <ai-icon class="LocationSuggestion-icon" :name="icon" />

    <span v-if="title || subtitle" class="LocationSuggestion-text" tabindex="0">
      <ai-typo
        v-if="title"
        as="span"
        variant="paragraph-04-bold"
        class="LocationSuggestion-textMain">
        {{ title }}
      </ai-typo>

      <ai-typo
        v-if="subtitle"
        as="span"
        variant="legal-regular"
        class="LocationSuggestion-textSecondary">
        {{ subtitle }}
      </ai-typo>
    </span>
  </button>
</template>

<script setup lang="ts">
import { IconName } from '~~/domains/ux/atoms/AiIcon/types';

export type Props = {
  title?: string;
  subtitle?: string;
  icon: IconName;
};
const props = defineProps<Props>();

type Emits = {
  (event: 'click', value: Props): void;
};
defineEmits<Emits>();
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';

.LocationSuggestion {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: constants.$inner-02;
  width: 100%;
  text-align: left;
  padding: constants.$inner-02;
  border-bottom: 1px solid colors.$gold-200;

  &:hover {
    background-color: colors.$gold-100;
  }
}

.LocationSuggestion-icon {
  fill: colors.$stratos-600;
}

.LocationSuggestion-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.LocationSuggestion-textSecondary {
  color: colors.$stratos-700;
}
</style>
